import Vue from "vue";
import Vuex from "vuex";
import api from "@/services/dataService";
import axios from "axios";
import notification from "@/services/notificationService";
import { DateTime } from "luxon";

Vue.use(Vuex);

function defaultState() {
  return {
    isLoggedIn: false,
    vehicles: undefined,
    vehicleDetail: undefined,
    vehicleExpense: undefined,
    tripDetail: undefined,
    tripExpenses: undefined,
    expenseDetail: undefined,
    tripData: undefined,
    users: undefined,
    userDetail: undefined,
    jobs: undefined,
    attachmentURLS: undefined,
    trips: undefined,
    alerts: undefined,
    alertHistorySummary: undefined,
    logbookUrl: undefined,
    overview: undefined,
    vehiclesLocation: undefined,
    vehiclesBehavior: undefined,
    dongleBehaviorEvents: undefined,
    fleetCategories: undefined,
    geoFences: undefined,
    selectedGeoFence: undefined,
    vehicleOverview: undefined,
    bookings: undefined,
    currentUser: undefined,
    currentUserRole: undefined,
    vehicleTripCategorySettings: undefined,
    allUsers: undefined,
    tenants: undefined,
    selectedTenant: undefined,
    vehicleExportFile: undefined,
    routeHistory: undefined,
    routeHistoryUnfiltered: undefined,
    mileageIgnoreZones: undefined,
    simsInfo: undefined,
    dongleVoltageEvents: undefined,
    internalDiagnosticsAllVehicles: undefined
  };
}

export default new Vuex.Store({
  state: defaultState,
  mutations: {
    resetStore(state: any) {
      // acquire initial state
      const s = defaultState() as any;
      Object.keys(s).forEach((key: string) => {
        state[key] = s[key];
      });
    },
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    setOverview(state, payload) {
      state.overview = payload;
    },
    setVehicleOverview(state, payload) {
      state.vehicleOverview = payload;
    },
    setTripData(state, payload) {
      state.tripData = payload;
    },
    setTripDetail(state, payload) {
      state.tripDetail = payload;
    },
    setTripExpenses(state, payload) {
      state.tripExpenses = payload;
    },
    setExpenseDetail(state, payload) {
      state.expenseDetail = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    setAllUsers(state, payload) {
      state.allUsers = payload;
    },
    setUserDetail(state, payload) {
      state.userDetail = payload;
    },
    setVehicles(state, payload) {
      state.vehicles = payload;
    },
    setVehicleDetail(state, payload) {
      state.vehicleDetail = payload;
    },
    setVehicleExpense(state, payload) {
      state.vehicleExpense = payload;
    },
    setJobs(state, payload) {
      state.jobs = payload;
    },
    setBookings(state, payload) {
      state.bookings = payload;
    },
    setAttachmentURLs(state, payload) {
      state.attachmentURLS = payload;
    },
    setTrips(state, payload) {
      state.trips = payload;
    },
    setAlerts(state, payload) {
      state.alerts = payload;
    },
    setAlertHistorytSummary(state, payload) {
      state.alertHistorySummary = payload;
    },
    setLogbookUrl(state, payload) {
      state.logbookUrl = payload;
    },
    setVehiclesLocation(state, payload) {
      state.vehiclesLocation = payload;
    },
    setVehiclesBehaviorStatus(state, payload) {
      state.vehiclesBehavior = payload;
    },
    setDongleBehaviorEvents(state, payload) {
      state.dongleBehaviorEvents = payload;
    },
    setDongleVoltageEvents(state, payload) {
      state.dongleVoltageEvents = payload.items;
    },
    updateAndAppendDongleVoltageEvents(state, payload) {
      state.dongleVoltageEvents = [
        ...(state.dongleVoltageEvents ? state.dongleVoltageEvents : []),
        ...payload.items
      ];
    },
    setInternalDiagnosticsAllVehicles(state, payload) {
      state.internalDiagnosticsAllVehicles = payload;
    },
    clearDongleBehaviorEvents(state) {
      state.dongleBehaviorEvents = undefined;
    },
    setGeoFences(state, payload) {
      state.geoFences = payload;
    },
    setSelectedGeoFence(state, payload) {
      state.selectedGeoFence = payload;
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setCurrentUserRole(state, payload) {
      state.currentUserRole = payload;
    },
    setVehicleTripCategorySettings(state, payload) {
      state.vehicleTripCategorySettings = payload;
    },
    setTenants(state, payload) {
      state.tenants = payload;
    },
    setSelectedTenant(state, payload) {
      state.selectedTenant = payload;
    },
    setVehicleExportFile(state, payload) {
      state.vehicleExportFile = payload;
    },
    setRouteHistory(state, payload) {
      state.routeHistory = payload;
    },
    setRouteHistoryUnfiltered(state, payload) {
      state.routeHistoryUnfiltered = payload;
    },
    setMileageIgnoreZones(state, payload) {
      state.mileageIgnoreZones = payload;
    },
    setSimsInfo(state, payload) {
      state.simsInfo = payload;
    },
    setFleetCategories(state, payload) {
      state.fleetCategories = payload;
    }
  },
  actions: {
    getTenants(state) {
      return api
        .get("/tenants")
        .then(res => {
          state.commit("setTenants", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    setSelectedTenant(state, payload) {
      state.commit("setSelectedTenant", payload);
    },
    resetStore(state) {
      state.commit("resetStore");
    },
    setIsLoggedIn(state, payload) {
      state.commit("setIsLoggedIn", payload);
    },
    updateUserDetail(state, { userId, userDetail }) {
      return api
        .put("/users/" + userId, userDetail)
        .then(res => {
          notification.success("User successfully updated");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    setCurrentUser(state, payload) {
      state.commit("setCurrentUser", payload);
    },
    async getOverview(state, payload) {
      await api
        .get("/overview", {
          params: {
            simplePeriod: payload
          }
        })
        .then(res => {
          state.commit("setOverview", res.data);
        })
        .catch(err => {
          state.commit("setOverview", undefined);
          notification.error(err.data.message);
        });
    },
    async getVehicleOverview(state, { vehicleId, simplePeriod }) {
      await api
        .get("/vehicles/" + vehicleId + "/overview", {
          params: {
            simplePeriod: simplePeriod
          }
        })
        .then(res => {
          state.commit("setVehicleOverview", res.data);
        })
        .catch(err => {
          state.commit("setVehicleOverview", undefined);
          notification.error(err.data.message);
        });
    },
    setOverview(state, payload) {
      state.commit("setOverview", payload);
    },
    setVehicleOverview(state, payload) {
      state.commit("setVehicleOverview", payload);
    },
    uploadAttachment(state, { attachment, url }) {
      return axios({
        method: "put",
        url: url,
        headers: {
          "Content-Type": attachment.type
        },
        data: attachment
      }).catch(err => {
        notification.error(err.message);
      });
    },
    getLogbookUrl(state, { mode, vehicles, timePeriod, startDate, endDate }) {
      if (mode == "period") {
        api
          .post("/logbook/export", vehicles, {
            params: {
              format: "pdf",
              simplePeriod: timePeriod
            }
          })
          .then(res => {
            state.commit("setLogbookUrl", res.data);
            notification.success("Logbook generated");
          })
          .catch(err => {
            state.commit("setLogbookUrl", undefined);
            notification.error(err.data.message);
          });
      } else if (mode == "range") {
        api
          .post("/logbook/export", vehicles, {
            params: {
              format: "pdf",
              startDate: startDate,
              endDate: endDate
            }
          })
          .then(res => {
            state.commit("setLogbookUrl", res.data);
            notification.success("Logbook generated");
          })
          .catch(err => {
            state.commit("setLogbookUrl", undefined);
            notification.error(err.data.message);
          });
      }
    },
    setLogbookUrl(state, payload) {
      state.commit("setLogbookUrl", payload);
    },
    getVehicleExportFile(state, { selectedTenant, filter }) {
      if (filter == "All") {
        api
          .get("/tenants/" + selectedTenant + "/vehicles/export")
          .then(res => {
            state.commit("setVehicleExportFile", res.data);
            notification.success("Vehicle list generated");
          })
          .catch(err => {
            state.commit("setVehicleExportFile", undefined);
            notification.error(err.data.message);
          });
      } else {
        api
          .get("/tenants/" + selectedTenant + "/vehicles/export", {
            params: {
              archived: filter === "Archived" ? true : false
            }
          })
          .then(res => {
            state.commit("setVehicleExportFile", res.data);
            notification.success("Vehicle list generated");
          })
          .catch(err => {
            state.commit("setVehicleExportFile", undefined);
            notification.error(err.data.message);
          });
      }
    },
    setVehicleExportFile(state, payload) {
      state.commit("setVehicleExportFile", payload);
    },
    async getAttachmentURLs(
      state,
      { vehicleId, attachmentNames, type = "expense" }
    ) {
      let request = "";
      if (type === "expense") {
        request = "/vehicles/" + vehicleId + "/expenses/request-upload-urls";
      } else if (type === "vehicle") {
        request = "/vehicles/" + vehicleId + "/request-upload-urls";
      }
      await api.post(request, attachmentNames).then(res => {
        state.commit("setAttachmentURLs", res.data);
      });
    },
    async getUsers(state, { page, archived, limit, selectedTenant }) {
      await api
        .get("/tenants/" + selectedTenant + "/users", {
          params: {
            page: page,
            archived: archived,
            limit: limit
          }
        })
        .then(res => {
          state.commit("setUsers", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getAllUsers(state, { page, archived, limit }) {
      await api
        .get("/users", {
          params: {
            page: page,
            archived: archived,
            limit: limit
          }
        })
        .then(res => {
          state.commit("setAllUsers", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getUserDetail(state, { selectedTenant, userId }) {
      api
        .get("/tenants/" + selectedTenant + "/users/" + userId)
        .then(res => {
          state.commit("setUserDetail", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    setUserDetail(state, payload) {
      state.commit("setUserDetail", payload);
    },
    getRawRouteHistory(state, request) {
      return api
        .get(
          "tenants/" +
            request.selectedTenant +
            "/vehicles/" +
            request.vehicleId +
            "/raw-routes",
          {
            params: { ...request, applyFilter: true }
          }
        )
        .then(res => {
          state.commit("setRouteHistory", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getRawRouteHistoryUnfiltered(state, request) {
      return api
        .get(
          "tenants/" +
            request.selectedTenant +
            "/vehicles/" +
            request.vehicleId +
            "/raw-routes",
          {
            params: { ...request, applyFilter: false }
          }
        )
        .then(res => {
          state.commit("setRouteHistoryUnfiltered", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getRouteHistory(state, request) {
      return api
        .get(
          "tenants/" +
            request.selectedTenant +
            "/vehicles/" +
            request.vehicleId +
            "/routes",
          {
            params: {
              date: request.date
            }
          }
        )
        .then(res => {
          state.commit("setRouteHistory", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    clearRouteHistory(state) {
      state.commit("setRouteHistory", undefined);
    },
    getVehicles(state, { selectedTenant }) {
      api
        .get("/tenants/" + selectedTenant + "/vehicles")
        .then(res => {
          state.commit("setVehicles", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getVehicleDetail(state, { selectedTenant, vehicleId }) {
      api
        .get("/tenants/" + selectedTenant + "/vehicles/" + vehicleId)
        .then(res => {
          state.commit("setVehicleDetail", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    updateVehicleDetail(state, { selectedTenant, vehicleId, request }) {
      api
        .put("/tenants/" + selectedTenant + "/vehicles/" + vehicleId, request)
        .then(res => {
          notification.success("Vehicle updated");
          state.commit("setVehicleDetail", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getVehicleExpense(state, vehicleId) {
      api
        .get("/vehicles/" + vehicleId + "/expenses")
        .then(res => {
          state.commit("setVehicleExpense", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getTripData(state, { selectedTenant, tripId }) {
      api
        .get("/tenants/" + selectedTenant + "/trips/" + tripId + "/events")
        .then(res => {
          state.commit("setTripData", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    setTripData(state, payload) {
      return state.commit("setTripDetail", payload);
    },
    async getTripDetail(state, { tripId, vehicleId, selectedTenant }) {
      await api
        .get(
          "/tenants/" +
            selectedTenant +
            "/vehicles/" +
            vehicleId +
            "/trips/" +
            tripId
        )
        .then(res => {
          state.commit("setTripDetail", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    setTripDetail(state, payload) {
      state.commit("setTripDetail", payload);
    },
    async updateTripDetail(state, { vehicleId, tripId, category, jobs }) {
      await api
        .put("/vehicles/" + vehicleId + "/trips/" + tripId, {
          category: category,
          jobs: jobs
        })
        .then(res => {
          state.commit("setTripDetail", res.data);
          notification.success("Trip successfully updated");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getTrips(
      state,
      {
        page,
        sort,
        startDate,
        endDate,
        showVehiclesId,
        category,
        limit,
        selectedTenant
      }
    ) {
      await api
        .get("/tenants/" + selectedTenant + "/trips", {
          params: {
            page: page,
            sort: sort,
            startDate: startDate,
            endDate: endDate,
            showVehiclesId: showVehiclesId,
            category: category,
            limit: limit
          }
        })
        .then(res => {
          state.commit("setTrips", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getAlerts(
      state,
      { page, limit, selectedTenant, alertTemplate, startDate, endDate }
    ) {
      await api
        .get("/tenants/" + selectedTenant + "/alert-history", {
          params: {
            page: page,
            limit: limit,
            alertTemplate: alertTemplate,
            startDate: startDate,
            endDate: endDate
          }
        })
        .then(res => {
          state.commit("setAlerts", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getAlertHistorySummary(
      state,
      { selectedTenant, alertTemplate, date }
    ) {
      await api
        .get("/tenants/" + selectedTenant + "/alert-history-summary", {
          params: {
            alertTemplate: alertTemplate,
            date: date
          }
        })
        .then(res => {
          state.commit("setAlertHistorytSummary", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getTripExpenses(state, { tripId, vehicleId }) {
      await api
        .get("/vehicles/" + vehicleId + "/expenses?tripId=" + tripId)
        .then(res => {
          state.commit("setTripExpenses", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getExpenseDetail(state, { vehicleId, expenseId }) {
      await api
        .get("/vehicles/" + vehicleId + "/expenses/" + expenseId)
        .then(res => {
          state.commit("setExpenseDetail", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    setExpenseDetail(state, payload) {
      state.commit("setExpenseDetail", payload);
    },
    async deleteExpense(state, { vehicleId, expenseId }) {
      await api
        .delete("/vehicles/" + vehicleId + "/expenses/" + expenseId)
        .catch(err => {
          if (err.status == 204) {
            notification.success("Expense deleted");
          } else {
            notification.error(err.data.message);
          }
        });
    },
    async updateExpense(state, { vehicleId, expenseId, updatedExpense }) {
      api
        .put(
          "/vehicles/" + vehicleId + "/expenses/" + expenseId,
          updatedExpense
        )
        .then(res => {
          state.commit("setExpenseDetail", res.data);
          notification.success("Expense updated");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async addGeoFence(state, geoFence) {
      await api
        .post("/geofences", geoFence)
        .then(async res => {
          notification.success("Geo-fence added");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async updateGeoFence(state, { id, geoFence }) {
      await api
        .put("/geofences/" + id, geoFence)
        .then(async res => {
          state.dispatch("getGeoFences");
          notification.success("Geo-fence updated");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async deleteGeoFence(state, id) {
      await api
        .delete("/geofences/" + id)
        .then(async res => {
          notification.success("Geo-fence deleted");
        })
        .catch(err => {
          if (err.status === 204) {
            notification.success("Geo-fence deleted");
          } else {
            notification.error(err.data.message);
          }
        });
    },
    async addExpense(state, { vehicleId, expenseDetail }) {
      await api
        .post("/vehicles/" + vehicleId + "/expenses/", expenseDetail)
        .then(async res => {
          await state.commit("setExpenseDetail", res.data);
        });
    },
    async addExpenseAttachment(
      state,
      { vehicleId, expenseId, attachmentKeys }
    ) {
      api
        .put("/vehicles/" + vehicleId + "/expenses/" + expenseId, {
          attachmentFileKeys: attachmentKeys
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async addVehicleAttachment(state, { vehicleId, attachmentKeys }) {
      api
        .put("/vehicles/" + vehicleId, {
          attachmentFileKeys: attachmentKeys
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getJobs(state) {
      await api
        .get("/jobs")
        .then(res => {
          state.commit("setJobs", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getBookings(
      state,
      { page, sort, startDate, endDate, showVehiclesId, category, limit }
    ) {
      await api
        .get("/bookings", {
          params: {
            page: page,
            sort: sort,
            startDate: startDate,
            endDate: endDate,
            showVehiclesId: showVehiclesId,
            category: category,
            limit: limit
          }
        })
        .then(res => {
          state.commit("setBookings", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async addBooking(state, booking) {
      await api
        .post("/bookings", booking)
        .then(async res => {
          notification.success("Booking added");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async updateBooking(state, { id, booking }) {
      await api
        .put("/bookings/" + id, booking)
        .then(async res => {
          notification.success("Booking updated");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async deleteBooking(state, id) {
      await api
        .delete("/bookings/" + id)
        .then(async res => {
          notification.success("Booking deleted");
        })
        .catch(err => {
          if (err.status === 204) {
            notification.success("Booking deleted");
          } else {
            notification.error(err.data.message);
          }
        });
    },
    async getVehicleTripCategorySettings(state) {
      await api
        .get("/settings/vehicleTripCategory")
        .then(res => {
          state.commit("setVehicleTripCategorySettings", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async updateVehicleTripCategorySettings(state, settings) {
      await api
        .put("/settings/vehicleTripCategory", {
          vehicleTripCategories: settings
        })
        .then(res => {
          this.dispatch("getVehicleTripCategorySettings");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getVehiclesLocation(state, { selectedTenant = undefined }) {
      await api
        .get("/tenants/" + selectedTenant + "/vehicles/location")
        .then(res => {
          state.commit("setVehiclesLocation", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getVehiclesBehaviorStatus(state, { selectedTenant = undefined }) {
      await api
        .get("/tenants/" + selectedTenant + "/dongle-events/behavior", {
          params: {
            date: new Date().toISOString()
          }
        })
        .then(res => {
          // perform sorting by timestamp, DESC
          const sorted = res.data.sort((a, b) => {
            const aD = DateTime.fromISO(a.updatedAt);
            const bD = DateTime.fromISO(b.updatedAt);
            return aD.toSeconds() - bD.toSeconds();
          });
          state.commit("setVehiclesBehaviorStatus", sorted);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async addJobToTrip(state, { addJobDetail, tripId }) {
      const request = {
        title: addJobDetail.title,
        description: addJobDetail.description,
        postcode: addJobDetail.postcode,
        startDate: addJobDetail.startDate,
        endDate: addJobDetail.endDate,
        status: addJobDetail.status,
        assignee: addJobDetail.assignee,
        tripId: tripId
      };
      api
        .post("/jobs", request)
        .then(res => {
          notification.success("Job added");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getGeoFences(state, { selectedTenant }) {
      api
        .get("/tenants/" + selectedTenant + "/geofences")
        .then(res => {
          state.commit("setGeoFences", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getSelectedGeoFence(state, id) {
      api
        .get("/geofences/" + id)
        .then(res => {
          state.commit("setSelectedGeoFence", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getCurrentUser(state) {
      api
        .get("/users/me")
        .then(res => {
          state.commit("setCurrentUser", res.data);
          state.commit("setCurrentUserRole", res.data.roles[0]);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    getDongleBehaviorEvents(state, request) {
      api
        .get("/dongle-events/" + request.dongleId + "/behavior", {
          params: {
            onlyVoltageEvents: request.onlyVoltageEvents
          }
        })
        .then(res => {
          state.commit("setDongleBehaviorEvents", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    setDongleBehaviorEvents(state, value) {
      state.commit("setDongleBehaviorEvents", value);
    },
    getDongleVoltageEvents(state, request, append = false) {
      return new Promise((resolve, reject) => {
        api
          .get("/dongle-events/" + request.dongleId + "/voltage-history", {
            params: {
              limit: request.limit,
              page: request.page
            }
          })
          .then(res => {
            if (append) {
              state.commit("updateAndAppendDongleVoltageEvents", res.data);
            } else {
              state.commit("setDongleVoltageEvents", res.data);
            }
            resolve(res.data);
          })
          .catch(err => {
            console.log("error", err);
            notification.error(err.data.message);
            reject(err);
          });
      });
    },
    setDongleVoltageEvents(state, value) {
      state.commit("setDongleVoltageEvents", value);
    },
    async addMileageIgnoreZone(state, mileageIgnoreZone) {
      await api
        .post(
          "/tenants/" + mileageIgnoreZone.tenant + "/mileage-ignore-zone",
          mileageIgnoreZone
        )
        .then(async res => {
          notification.success("Mileage Ignore Zone added");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getMileageIgnoreZones(state, request) {
      await api
        .get("/tenants/" + request.tenant + "/mileage-ignore-zones")
        .then(async res => {
          state.commit("setMileageIgnoreZones", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async updateMileageIgnoreZone(state, request) {
      await api
        .put(
          "/tenants/" +
            request.tenant +
            "/mileage-ignore-zone/" +
            request.mileageIgnoreZoneId,
          { name: request.name, detail: request.detail }
        )
        .then(async res => {
          notification.success("Mileage ignore zone updated");
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async deleteMileageIgnoreZone(state, request) {
      await api
        .delete(
          "/tenants/" +
            request.tenant +
            "/mileage-ignore-zone/" +
            request.mileageIgnoreZoneId
        )
        .catch(err => {
          if (err.status === 204) {
            notification.success("Mileage ignore zone deleted");
          }
        });
    },
    async getSimsInfo(state) {
      await api
        .get("/dongles/sim-info/")
        .then(async res => {
          state.commit("setSimsInfo", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getFleetCategories(state, { selectedTenant }) {
      await api
        .get("tenants/" + selectedTenant + "/settings/fleet-categories")
        .then(res => {
          state.commit("setFleetCategories", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    },
    async getInternalDiagnosticsAllVehicles(state, params) {
      await api
        .get("internal-diagnostics/all-vehicles", {
          params
        })
        .then(res => {
          state.commit("setInternalDiagnosticsAllVehicles", res.data);
        })
        .catch(err => {
          notification.error(err.data.message);
        });
    }
  },
  modules: {},
  getters: {
    getIsLoggedIn(state) {
      return state.isLoggedIn;
    },
    getVehicleDetail(state) {
      return state.vehicleDetail;
    }
  }
});
