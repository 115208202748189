import { ToastProgrammatic as Toast } from "buefy";

const toastPosition = "is-bottom";

const notification = {
  success: (msg: string) => {
    Toast.open({
      message: msg,
      type: "is-success",
      position: toastPosition
    });
  },
  warning: (msg: string) => {
    Toast.open({
      message: msg,
      type: "is-warning",
      position: toastPosition
    });
  },
  error: (msg: string) => {
    Toast.open({
      message: msg,
      type: "is-danger",
      position: toastPosition
    });
  }
};

export default notification;
