import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import { Auth } from "aws-amplify";
import "@/shared/filters";

import "@mdi/font/css/materialdesignicons.min.css";
// custom global css
import "./assets/css/main.scss";

import Default from "@/layouts/Default.vue";
import NoNavbar from "@/layouts/NoNavbar.vue";

Vue.component("default-layout", Default);
Vue.component("no-navbar-layout", NoNavbar);

Vue.config.productionTip = false;
Vue.use(Buefy);

Auth.configure({
  region: process.env.VUE_APP_AMAZON_REGION,
  userPoolId: process.env.VUE_APP_AMAZON_USER_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_AMAZON_USER_POOL_WEB_CLIENT_ID
});

Auth.currentSession().then(async (res: any) => {
  // await store.dispatch("getCurrentUser");
  store.dispatch("setIsLoggedIn", true);
});

new Vue({
  router,
  store,
  // eslint-disable-next-line
  render: h => h(App)
}).$mount("#app");
