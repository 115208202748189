import axios from "axios";
import router from "../router";
import store from "../store";
import { Auth } from "aws-amplify";
import Nprogress from "nprogress";

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

api.interceptors.request.use(function(config) {
  return new Promise((resolve, reject) => {
    Nprogress.start();
    Auth.currentSession()
      .then((res: any) => {
        config.headers.Authorization =
          "Bearer " + res.getIdToken().getJwtToken();
        resolve(config);
      })
      .catch(() => {
        resolve(config);
      });
  });
});

api.interceptors.response.use(
  response => {
    Nprogress.done();
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  async error => {
    Nprogress.done();
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          break;
        case 401:
          await Auth.signOut();
          store.dispatch("resetStore");
          store.dispatch("setIsLoggedIn", false);
          store.dispatch("setCurrentUser", undefined);
          router.push({ name: "LogIn" });
          break;
        case 403:
          // in case of forbidden, logout user
          await Auth.signOut();
          store.dispatch("resetStore");
          store.dispatch("setIsLoggedIn", false);
          store.dispatch("setCurrentUser", undefined);
          router.push({ name: "LogIn" });
          break;
        case 404:
          break;
        case 502:
      }
      return Promise.reject(error.response);
    }
  }
);

export default api;
