<template>
  <div class="top-bar">
    <div class="columns p-5">
      <div class="column is-3">
        <div class="columns pb-0 mb-0">
          <div class="column pb-0"><p class="text-bold">Tenant:</p></div>
          <div v-if="selectedTenant" class="column pb-0 is-narrow">
            <a @click="clearSelectedTenant()">
              <b-tag type="is-light" rounded>
                <i class="mdi mdi-close pr-1"></i>Clear
              </b-tag>
            </a>
          </div>
        </div>
        <b-dropdown
          @input="selectTenant()"
          v-model="selectedTenant"
          class="mt-3"
          placeholder="Select a tenant"
          aria-role="list"
          expanded
          :disabled="!tenants"
        >
          <button class="button is-fullwidth" type="button" slot="trigger">
            <span v-if="this.selectedTenant"
              >{{ this.selectedTenant.companyName }} selected</span
            ><span v-else>{{
              tenants && tenants.length ? "Select a tenant" : "Loading Tenants"
            }}</span>
            <b-icon v-if="tenants && tenants.length" icon="menu-down"></b-icon>
          </button>
          <div v-for="tenant in tenants" :key="tenant.id">
            <b-dropdown-item :value="tenant" aria-role="listitem">
              <div>
                <p class="text-small">{{ tenant.companyName }}</p>
                <p>{{ tenant.email }}</p>
              </div>
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </div>
      <div v-if="selectedTenant" class="column">
        <p>
          {{ selectedTenant.email }}
          <b-tag type="is-info is-light" rounded>{{
            selectedTenant.customerNo
          }}</b-tag>
        </p>
        <p class="text-small text-bold">
          {{ selectedTenant.companyName }}
        </p>
        <p class="text-small">{{ selectedTenant.billingAddress }}</p>
        <p class="text-small">{{ selectedTenant.phoneNo }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import router from "@/router";
import store from "@/store";
import find from "lodash/find";
import sortBy from "lodash/sortBy";

export default Vue.extend({
  name: "Topbar",
  data() {
    return {
      selectedTenant: undefined
    };
  },
  computed: {
    tenants() {
      if (this.$store.state.tenants) {
        return sortBy(this.$store.state.tenants, ["companyName"]);
      }
      return [];
    },
    storedSelectedTenant() {
      return this.$store.state.selectedTenant;
    }
  },
  async created() {
    await this.$store.dispatch("getTenants");
    if (this.$route.query.selectedTenant) {
      this.$store.dispatch(
        "setSelectedTenant",
        this.$route.query.selectedTenant
      );
      this.selectedTenant = find(this.tenants, {
        id: this.$route.query.selectedTenant
      });
    } else if (this.storedSelectedTenant) {
      this.selectedTenant = find(this.tenants, {
        id: this.storedSelectedTenant
      });
    }
  },
  methods: {
    selectTenant() {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          selectedTenant: this.selectedTenant.id
        }
      });
      this.$store.dispatch("setSelectedTenant", this.selectedTenant.id);
    },
    clearSelectedTenant() {
      this.selectedTenant = undefined;
      this.$store.dispatch("setSelectedTenant", undefined);
      this.$router.replace({
        path: this.$route.path,
        query: {}
      });
    }
  }
});
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.top-bar {
  background-color: $white;
  border-bottom: 5px solid $primary-color;
}

.text-light {
  color: $white !important;
}
</style>
