import Vue from "vue";
import { DateTime, Duration, DurationUnit } from "luxon";

const timeAgo = date => {
  // source https://github.com/moment/luxon/issues/274#issuecomment-729126515
  const units = [
    "year",
    "month",
    "week",
    "day",
    "hour",
    "minute",
    "second"
  ] as DurationUnit[];

  const dateTime = DateTime.fromISO(date);
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find(unit => diff.get(unit) !== 0) || "second";

  // todo: using any until we upgrade to TypeSript versin 4.2
  const relativeFormatter = new (Intl as any).RelativeTimeFormat("en", {
    numeric: "auto"
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit as any);
};

Vue.filter("date", (value: string, type = "default") => {
  if (value == null) {
    return "-";
  } else if (type == "date-time") {
    return DateTime.fromISO(value).toFormat("dd/MM/yyyy, hh:mm a");
  } else if (type == "time-date") {
    return DateTime.fromISO(value).toFormat("hh:mm a, dd/MM/yyyy");
  } else if (type == "date-time-seconds") {
    return DateTime.fromISO(value).toFormat("dd LLL yyyy, hh:mm:ss a");
  } else if (type == "date-time-seconds-gmt") {
    return DateTime.fromISO(value, { zone: "gmt" }).toFormat(
      "dd LLL yyyy, hh:mm:ss a"
    );
  } else if (type == "time-date-seconds-no-year") {
    return DateTime.fromISO(value).toFormat("hh:mm:ss a, dd LLL");
  } else if (type == "time-date-seconds") {
    return DateTime.fromISO(value).toFormat("hh:mm:ss a, dd/MM/yyyy");
  } else if (type == "time") {
    return DateTime.fromISO(value).toFormat("hh:mm a");
  } else if (type == "time-seconds") {
    return DateTime.fromISO(value).toFormat("hh:mm:ss a");
  } else if (type == "day") {
    return DateTime.fromISO(value).toFormat("EEEE");
  } else if (type == "numeric-short") {
    return DateTime.fromISO(value).toFormat("dd/MM");
  } else if (type == "short") {
    return DateTime.fromISO(value).toLocaleString({
      weekday: "short",
      month: "short",
      day: "2-digit"
    });
  } else if (type == "short-with-day") {
    return DateTime.fromISO(value).toLocaleString({
      weekday: "short",
      month: "short",
      year: "numeric",
      day: "2-digit"
    });
  } else if (type == "medium") {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
  } else if (type == "time-date-medium") {
    return (
      DateTime.fromISO(value).toFormat("hh:mm a, ") +
      DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)
    );
  } else if (type == "relative") {
    return timeAgo(value);
  } else {
    // Default filter for date
    return DateTime.fromISO(value).toFormat("dd/MM/yyyy");
  }
});

Vue.filter("timeDuration", (value: string, endTime: string) => {
  const duration = Duration.fromObject(
    DateTime.fromISO(endTime)
      .diff(DateTime.fromISO(value), "minutes")
      .toObject()
  );
  let formattedDuration = undefined;
  if (duration.minutes > 60) {
    formattedDuration = duration.toFormat("hh Hr mm") + " Mins";
  } else if (duration.minutes < 1) {
    formattedDuration = "01 Mins";
  } else {
    formattedDuration = duration.toFormat("mm") + " Mins";
  }
  return formattedDuration;
});

Vue.filter("formatNullvalue", (value: string | number, placeholder: string) => {
  if (value == null || value === 0) {
    if (placeholder) return placeholder;
    else return "-";
  } else {
    return value;
  }
});

Vue.filter("capitalize", (value: string) => {
  if (!value) return "";
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
