var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"login columns"},[_c('div',{staticClass:"column is-5 pl-6 pr-6"},[_c('section',{staticClass:"section"},[_vm._m(0),_c('p',{staticClass:"is-size-4"},[_vm._v(" Welcome to the Backstage 1.0 ")]),(!_vm.challenge && !this.$route.query.view)?_c('div',{key:"1"},[_c('p',{staticClass:"mt-3"},[_vm._v("Please login to your account")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.signIn.apply(null, arguments)}}},[_c('p',{staticClass:"mt-6"},[_vm._v("Email")]),_c('b-input',{key:"1.1",staticClass:"mt-3",attrs:{"type":"email","required":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('p',{staticClass:"mt-3"},[_vm._v("Password")]),_c('b-input',{key:"1.2",staticClass:"mt-3",attrs:{"type":"password","required":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('p',{staticClass:"mt-3"},[_c('a',{staticClass:"is-active",on:{"click":() =>
                    this.$router.push({
                      query: {
                        ...this.$route.query,
                        view: 'forgot-password'
                      }
                    })}},[_vm._v("Forgot Password")])]),_c('input',{key:"1.3",staticClass:"button button-primary is-fullwidth mt-5",attrs:{"type":"submit","value":"Login"}})],1)]):_vm._e(),(_vm.challenge && !this.$route.query.view)?_c('div',{key:"2"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.completeChallenge.apply(null, arguments)}}},[_c('p',{staticClass:"mt-3"},[_vm._v("Please enter your new password")]),_c('p',{staticClass:"mt-6"},[_vm._v("Password")]),_c('b-input',{key:"2.1",staticClass:"mt-3",attrs:{"type":"password"},model:{value:(_vm.newPasswordChallenge),callback:function ($$v) {_vm.newPasswordChallenge=$$v},expression:"newPasswordChallenge"}}),_c('input',{key:"2.2",staticClass:"button button-primary is-fullwidth mt-5",attrs:{"type":"submit","value":"Update Password"}})],1)]):_vm._e(),(this.$route.query.view == 'forgot-password')?_c('div',{key:"3"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendRecoveryCode.apply(null, arguments)}}},[_c('p',{staticClass:"mt-3"},[_vm._v(" Please enter the email address you signed up with ")]),_c('p',{staticClass:"mt-3"},[_c('a',{staticClass:"is-active",on:{"click":() =>
                    this.$router.push({
                      query: {
                        ...this.$route.query,
                        view: null
                      }
                    })}},[_vm._v("Go back to login")])]),_c('p',{staticClass:"mt-6"},[_vm._v("Email")]),_c('b-input',{key:"3.1",staticClass:"mt-3",attrs:{"type":"email"},model:{value:(_vm.recoveryEmail),callback:function ($$v) {_vm.recoveryEmail=$$v},expression:"recoveryEmail"}}),_c('input',{key:"3.2",staticClass:"button button-primary is-fullwidth mt-5",attrs:{"type":"submit","value":"Reset Password"}})],1)]):_vm._e(),(this.$route.query.view == 'reset-password')?_c('div',{key:"4"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.setNewPassword.apply(null, arguments)}}},[_c('p',{staticClass:"mt-3"},[_vm._v(" Please enter new confirmation code and new password ")]),_c('p',{staticClass:"mt-3"},[_c('a',{staticClass:"is-active",on:{"click":() =>
                    this.$router.push({
                      query: {
                        ...this.$route.query,
                        view: null
                      }
                    })}},[_vm._v("Go back to login")])]),_c('p',{staticClass:"mt-6"},[_vm._v("Confirmation Code")]),_c('b-input',{key:"4.1",staticClass:"mt-3",attrs:{"type":"number"},model:{value:(_vm.confirmationCode),callback:function ($$v) {_vm.confirmationCode=$$v},expression:"confirmationCode"}}),_c('p',{staticClass:"mt-5"},[_vm._v("New Password")]),_c('b-input',{key:"4.2",staticClass:"mt-3",attrs:{"type":"password"},model:{value:(_vm.newPasswordReset),callback:function ($$v) {_vm.newPasswordReset=$$v},expression:"newPasswordReset"}}),_c('input',{key:"4.3",staticClass:"button button-primary is-fullwidth mt-5",attrs:{"type":"submit","value":"Reset Password"},on:{"click":_vm.setNewPassword}})],1)]):_vm._e()])]),_c('div',{staticClass:"cover-bg column is-7"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('img',{staticClass:"login-logo mt-5",attrs:{"src":require("@/assets/img/connectia-primary.svg")}})])
}]

export { render, staticRenderFns }