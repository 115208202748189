
import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/Sidebar.vue";

const defaultLayout = "default";

export default Vue.extend({
  name: "App",
  components: {
    Sidebar
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    }
  }
});
