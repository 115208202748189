
import Vue from "vue";
import { Auth } from "aws-amplify";
import notification from "@/services/notificationService";

export default Vue.extend({
  name: "LogIn",
  data() {
    return {
      user: {
        username: "",
        password: ""
      },
      challenge: false,
      recoveryEmail: "",
      newPasswordReset: "",
      newPasswordChallenge: "",
      confirmationCode: ""
    };
  },
  methods: {
    completeChallenge: async function(e: Event): Promise<void> {
      try {
        const user = await Auth.signIn(this.user.username, this.user.password);
        if (user) {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, this.newPasswordChallenge)
              .then(async userObj => {
                if (userObj) {
                  // await this.$store.dispatch("getCurrentUser");
                  this.$store.dispatch("setIsLoggedIn", true);
                  this.$router.push({ name: "Dashboard" });
                }
              })
              .catch(err => {
                notification.error(err.message);
              });
          }
        }
      } catch (err) {
        notification.error(err.message);
      }
    },
    signIn: async function(e: Event): Promise<void> {
      notification.warning("Logging in");
      try {
        const user = await Auth.signIn(this.user.username, this.user.password);
        if (user) {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.challenge = true;
          } else {
            const userObj = await Auth.currentAuthenticatedUser();
            if (userObj) {
              // await this.$store.dispatch("getCurrentUser");
              this.$store.dispatch("setIsLoggedIn", true);
              this.$router.push({ name: "Dashboard" });
            }
          }
        }
      } catch (err) {
        notification.error(err.message);
      }
    },
    sendRecoveryCode() {
      Auth.forgotPassword(this.recoveryEmail)
        .then(data => {
          notification.success("Confirmation Code sent via email");
          this.$router.push({
            query: {
              ...this.$route.query,
              view: "reset-password"
            }
          });
        })
        .catch(err => {
          notification.error(err.message);
        });
    },
    setNewPassword() {
      Auth.forgotPasswordSubmit(
        this.recoveryEmail,
        this.confirmationCode,
        this.newPasswordReset
      )
        .then(data => {
          notification.success("Password updated successfully");
          this.$router.push({
            query: {
              ...this.$route.query,
              view: null
            }
          });
        })
        .catch(err => {
          notification.error(err.message);
        });
    }
  }
});
