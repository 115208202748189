<template>
  <b-sidebar
    position="fixed"
    :fullheight="fullheight"
    :expand-on-hover="expandOnHover"
    :reduce="reduce"
    :mobile="mobile"
    :can-cancel="false"
    open
  >
    <div class="p-4">
      <div class="block is-centered pb-5 pt-5">
        <img src="@/assets/img/connectia.svg" alt="" class="ml-2" />
        <p class="ml-2 text-bold text-light">BACKSTAGE</p>
      </div>
      <b-menu class="is-custom-mobile">
        <b-menu-list>
          <b-menu-item
            icon="map"
            tag="router-link"
            :to="{
              name: 'LiveMap',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Live View"
          ></b-menu-item>

          <b-menu-item
            icon="car-multiple"
            label="Fleet"
            tag="router-link"
            :to="{
              name: 'Vehicles',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
          ></b-menu-item>

          <b-menu-item
            icon="car"
            label="Vehicle List"
            tag="router-link"
            :to="{
              name: 'VehicleListExport',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
          ></b-menu-item>

          <b-menu-item
            icon="navigation"
            tag="router-link"
            :to="{
              name: 'Trips',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Trips"
          ></b-menu-item>

          <b-menu-item
            icon="history"
            tag="router-link"
            :to="{
              name: 'RouteHistory',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Route History"
          ></b-menu-item>

          <b-menu-item
            icon="mapbox"
            tag="router-link"
            :to="{
              name: 'GeoFence',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Geo-fencing"
          ></b-menu-item>

          <b-menu-item
            icon="mapbox"
            tag="router-link"
            :to="{
              name: 'MileageIgnoreZones',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="M.I.Z"
          ></b-menu-item>

          <b-menu-item
            icon="alert"
            tag="router-link"
            :to="{
              name: 'Alerts',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Alerts"
          ></b-menu-item>

          <b-menu-item
            icon="image-broken-variant"
            tag="router-link"
            :to="{
              name: 'VehiclesBehavior',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Veh Behavior"
          ></b-menu-item>

          <b-menu-item
            icon="bell-alert"
            tag="router-link"
            :to="{
              name: 'AlertsSummary',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Alerts Summary"
          ></b-menu-item>

          <b-menu-item
            icon="account-multiple"
            tag="router-link"
            :to="{
              name: 'Users',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Users"
          ></b-menu-item>
        </b-menu-list>

        <b-menu-list label="Advance">
          <b-menu-item
            icon="car"
            tag="router-link"
            :to="{
              name: 'InternalDiagnosticsAllVehicles'
            }"
            label="Analyze Vehicles"
          ></b-menu-item>
          <b-menu-item
            icon="credit-card-wireless"
            tag="router-link"
            :to="{
              name: 'DongleEvents',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Dongle Events"
          ></b-menu-item>
          <b-menu-item
            icon="magnify"
            tag="router-link"
            :to="{
              name: 'VehicleSearch',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Vehicle Search"
          ></b-menu-item>
          <b-menu-item
            icon="sim-outline"
            tag="router-link"
            :to="{
              name: 'SimsInfo',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Sims Info"
          ></b-menu-item>
          <b-menu-item
            icon="file-multiple"
            tag="router-link"
            :to="{
              name: 'Orders',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Orders"
          ></b-menu-item>
          <b-menu-item
            icon="account-supervisor"
            tag="router-link"
            :to="{
              name: 'Tenants',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Tenants"
          ></b-menu-item>
          <b-menu-item
            icon="account-group"
            tag="router-link"
            :to="{
              name: 'AllUsers',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="All Users"
          ></b-menu-item>
        </b-menu-list>

        <b-menu-list label="Account">
          <b-menu-item
            icon="cog"
            tag="router-link"
            :to="{
              name: 'Settings',
              query: { selectedTenant: this.$route.query.selectedTenant }
            }"
            label="Settings"
          ></b-menu-item>
          <b-menu-item
            @click="signOut"
            icon="logout"
            label="Logout"
          ></b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
  </b-sidebar>
</template>

<script>
import { Auth } from "aws-amplify";
import router from "@/router";
import store from "@/store";
import notification from "@/services/notificationService";

export default {
  name: "Sidebar",
  data() {
    return {
      expandOnHover: false,
      mobile: "hide",
      reduce: false,
      fullheight: true
    };
  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut();
        store.dispatch("resetStore");
        store.dispatch("setIsLoggedIn", false);
        store.dispatch("setCurrentUser", undefined);
        router.push({ name: "LogIn" });
      } catch (err) {
        notification.error(err.message);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.menu-label {
  color: $text-light !important;
}
.menu-list a {
  color: $text-light !important;
}
.menu-list a i {
  color: $accent-color !important;
}
.menu-list a.is-active {
  background-color: $primary-color !important;
}
.menu-list a:hover {
  background-color: $primary-color !important;
}
.b-sidebar .sidebar-content.is-fullheight {
  background-color: $royal-blue !important;
}
.b-sidebar .sidebar-content {
  width: 220px !important;
}

.text-light {
  color: $white !important;
}
</style>
