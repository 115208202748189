<template>
  <div class="sidebar-page">
    <section class="sidebar-layout">
      <Sidebar />
      <div class="main-view">
        <Topbar v-if="hasTopBar" />
        <slot />
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import Sidebar from "../components/Sidebar.vue";
import Topbar from "../components/Topbar.vue";

export default Vue.extend({
  components: {
    Sidebar,
    Topbar
  },
  computed: {
    hasTopBar() {
      if (this.$route.meta.hasTopBar === false) {
        return false;
      } else {
        return true;
      }
    }
  }
});
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.main-view {
  flex-grow: 1;
  padding-left: 220px;
}

@media (max-width: $tablet-breakpoint) {
  .main-view {
    padding-left: 0px;
  }
}

.sidebar-layout {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}
</style>
