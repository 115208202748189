var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-sidebar',{attrs:{"position":"fixed","fullheight":_vm.fullheight,"expand-on-hover":_vm.expandOnHover,"reduce":_vm.reduce,"mobile":_vm.mobile,"can-cancel":false,"open":""}},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"block is-centered pb-5 pt-5"},[_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/img/connectia.svg"),"alt":""}}),_c('p',{staticClass:"ml-2 text-bold text-light"},[_vm._v("BACKSTAGE")])]),_c('b-menu',{staticClass:"is-custom-mobile"},[_c('b-menu-list',[_c('b-menu-item',{attrs:{"icon":"map","tag":"router-link","to":{
            name: 'LiveMap',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Live View"}}),_c('b-menu-item',{attrs:{"icon":"car-multiple","label":"Fleet","tag":"router-link","to":{
            name: 'Vehicles',
            query: { selectedTenant: this.$route.query.selectedTenant }
          }}}),_c('b-menu-item',{attrs:{"icon":"car","label":"Vehicle List","tag":"router-link","to":{
            name: 'VehicleListExport',
            query: { selectedTenant: this.$route.query.selectedTenant }
          }}}),_c('b-menu-item',{attrs:{"icon":"navigation","tag":"router-link","to":{
            name: 'Trips',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Trips"}}),_c('b-menu-item',{attrs:{"icon":"history","tag":"router-link","to":{
            name: 'RouteHistory',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Route History"}}),_c('b-menu-item',{attrs:{"icon":"mapbox","tag":"router-link","to":{
            name: 'GeoFence',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Geo-fencing"}}),_c('b-menu-item',{attrs:{"icon":"mapbox","tag":"router-link","to":{
            name: 'MileageIgnoreZones',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"M.I.Z"}}),_c('b-menu-item',{attrs:{"icon":"alert","tag":"router-link","to":{
            name: 'Alerts',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Alerts"}}),_c('b-menu-item',{attrs:{"icon":"image-broken-variant","tag":"router-link","to":{
            name: 'VehiclesBehavior',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Veh Behavior"}}),_c('b-menu-item',{attrs:{"icon":"bell-alert","tag":"router-link","to":{
            name: 'AlertsSummary',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Alerts Summary"}}),_c('b-menu-item',{attrs:{"icon":"account-multiple","tag":"router-link","to":{
            name: 'Users',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Users"}})],1),_c('b-menu-list',{attrs:{"label":"Advance"}},[_c('b-menu-item',{attrs:{"icon":"car","tag":"router-link","to":{
            name: 'InternalDiagnosticsAllVehicles'
          },"label":"Analyze Vehicles"}}),_c('b-menu-item',{attrs:{"icon":"credit-card-wireless","tag":"router-link","to":{
            name: 'DongleEvents',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Dongle Events"}}),_c('b-menu-item',{attrs:{"icon":"magnify","tag":"router-link","to":{
            name: 'VehicleSearch',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Vehicle Search"}}),_c('b-menu-item',{attrs:{"icon":"sim-outline","tag":"router-link","to":{
            name: 'SimsInfo',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Sims Info"}}),_c('b-menu-item',{attrs:{"icon":"file-multiple","tag":"router-link","to":{
            name: 'Orders',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Orders"}}),_c('b-menu-item',{attrs:{"icon":"account-supervisor","tag":"router-link","to":{
            name: 'Tenants',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Tenants"}}),_c('b-menu-item',{attrs:{"icon":"account-group","tag":"router-link","to":{
            name: 'AllUsers',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"All Users"}})],1),_c('b-menu-list',{attrs:{"label":"Account"}},[_c('b-menu-item',{attrs:{"icon":"cog","tag":"router-link","to":{
            name: 'Settings',
            query: { selectedTenant: this.$route.query.selectedTenant }
          },"label":"Settings"}}),_c('b-menu-item',{attrs:{"icon":"logout","label":"Logout"},on:{"click":_vm.signOut}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }