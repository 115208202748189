import store from "@/store";
import Vue from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import { Auth } from "aws-amplify";
import Nprogress from "nprogress";
import "nprogress/nprogress.css";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "LogIn",
    meta: { layout: "NoNavbar" },
    component: require("../views/LogIn.vue").default
  },
  {
    path: "/place-order",
    name: "PlaceOrder",
    component: () =>
      import(/* webpackChunkName: "PlaceOrder" */ "../views/PlaceOrder.vue")
  },
  {
    path: "/",
    name: "Index",
    redirect: {
      name: "LiveMap"
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    redirect: {
      name: "LiveMap"
    },
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Dashboard.vue")
  },
  {
    path: "/trips/detail",
    name: "TripDetail",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "TripDetail" */ "../views/TripDetail.vue")
  },
  {
    path: "/trips",
    name: "Trips",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "Trips" */ "../views/Trips.vue")
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: () =>
      import(/* webpackChunkName: "Alerts" */ "../views/Alerts.vue")
  },
  {
    path: "/alerts-summary",
    name: "AlertsSummary",
    component: () =>
      import(/* webpackChunkName: "Alerts" */ "../views/AlertsSummary.vue")
  },
  {
    path: "/vehicles-behavior",
    name: "VehiclesBehavior",
    component: () =>
      import(
        /* webpackChunkName: "Alerts" */ "../views/MisbehavingVehicles.vue"
      )
  },
  {
    path: "/vehicles",
    name: "Vehicles",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "Vehicles" */ "../views/Vehicles.vue")
  },
  {
    path: "/vehicle-list/export",
    name: "VehicleListExport",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "VehicleListExport" */ "../views/VehicleListExport.vue"
      )
  },
  {
    path: "/vehicles/archived",
    name: "ArchivedVehicles",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "ArchivedVehicles" */ "../views/ArchivedVehicles.vue"
      )
  },
  {
    path: "/vehicles/detail/",
    name: "VehicleDetail",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "VehicleDetail" */ "../views/VehicleDetail.vue"
      )
  },
  {
    path: "/route-history",
    name: "RouteHistory",
    component: () =>
      import(/* webpackChunkName: "Bookings" */ "../views/RouteHistory.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/bookings",
    name: "Bookings",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "Bookings" */ "../views/Bookings.vue")
  },
  {
    path: "/jobs",
    name: "Jobs",
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Jobs" */ "../views/Jobs.vue")
  },
  {
    path: "/orders",
    name: "Orders",
    meta: {
      hasTopBar: false,
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "Jobs" */ "../views/Orders.vue")
  },
  {
    path: "/tenants",
    name: "Tenants",
    meta: {
      requiresAuth: true,
      hasTopBar: false
    },
    component: () =>
      import(/* webpackChunkName: "Jobs" */ "../views/Tenants.vue")
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "Users" */ "../views/Users.vue")
  },
  {
    path: "/all/users",
    name: "AllUsers",
    meta: {
      requiresAuth: true,
      hasTopBar: false
    },
    component: () =>
      import(/* webpackChunkName: "Users" */ "../views/AllUsers.vue")
  },
  {
    path: "/logbook",
    name: "Logbook",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "Logbook" */ "../views/Logbook.vue")
  },
  {
    path: "/live",
    name: "LiveMap",
    meta: {
      requiresAuth: true
    },
    component: () => import("../views/LiveMap.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    meta: {
      requiresAuth: true,
      hasTopBar: false
    },
    component: () => import("../views/Settings.vue")
  },
  {
    path: "/geofence",
    name: "GeoFence",
    meta: {
      requiresAuth: true
    },
    component: () => import("../views/GeoFence.vue")
  },
  {
    path: "/geofence/map",
    name: "GeoFenceMap",
    meta: {
      requiresAuth: true
    },
    component: () => import("../views/GeoFenceMap.vue")
  },
  {
    path: "/mileage-ignore-zone/add",
    name: "MileageIgnoreZoneMap",
    component: () =>
      import(
        /* webpackChunkName: "MileageIgnoreZones" */ "../views/MileageIgnoreZonesMap.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/mileage-ignore-zones",
    name: "MileageIgnoreZones",
    component: () =>
      import(
        /* webpackChunkName: "MileageIgnoreZones" */ "../views/MileageIgnoreZones.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dongle/events/",
    name: "DongleEvents",
    component: () => import("../views/DongleEvents.vue"),
    meta: {
      requiresAuth: true,
      hasTopBar: false
    }
  },
  {
    path: "/vehicle-search",
    name: "VehicleSearch",
    component: () =>
      import(/* webpackChunkName: "Alerts" */ "../views/VehicleSearch.vue"),
    meta: {
      requiresAuth: true,
      hasTopBar: false
    }
  },
  {
    path: "/sims-info",
    name: "SimsInfo",
    component: () =>
      import(/* webpackChunkName: "Alerts" */ "../views/SimsInfo.vue"),
    meta: {
      requiresAuth: true,
      hasTopBar: false
    }
  },
  {
    path: "/diagnostics/vehicles",
    name: "InternalDiagnosticsAllVehicles",
    meta: {
      requiresAuth: true,
      hasTopBar: false
    },
    component: () =>
      import(
        /* webpackChunkName: "InternalDiagnosticsAllVehicles" */ "../views/non-tenant/InternalAllVehicles.vue"
      )
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: () => import("../views/NotFound.vue")
  },
  {
    path: "*",
    redirect: "/notfound"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "is-active"
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    await Auth.currentSession()
      .then(res => {
        next();
      })
      .catch(() => {
        store.dispatch("resetStore");
        store.dispatch("setIsLoggedIn", false);
        store.dispatch("setCurrentUser", undefined);
        next({
          name: "LogIn"
        });
      });
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    Nprogress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  Nprogress.done();
});

// Resolve for NavigationDuplicated Errors
router.push = async function(location: RawLocation) {
  let route: Route;
  try {
    route = await VueRouter.prototype.push.call(this, location);
  } catch (err) {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  }
  return route!;
};
router.replace = async function(location: RawLocation) {
  let route: Route;
  try {
    route = await VueRouter.prototype.push.call(this, location);
  } catch (err) {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  }
  return route!;
};

export default router;
